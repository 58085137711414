import { marketingLayoutFactory } from "../../components/masterLayouts/marketing";

export const tocData = [
    // {
    //     link: "/intro/affiliate",
    //     text: "Build faster and earn recurring commissions",
    //     points: [
    //         { title: "Use WebSemaphore to finish a project sooner" },
    //         { title: "Get rewarded every time the customer makes transactions" }
    //     ]
    // },
    {
        link: "/intro/business",
        text: "Reliable near real-time API operations",
        points: [
            { title: "Optimal resource usage / cost ratio" },
            { title: "Improved customer experience" },
            { title: "Reduced energy consumption and emissions" }
        ]
    },
    {
        link: "/intro/developer",
        text: "The easiest way to manage concurrency",
        points: [
            { title: "Zero setup, fully serverless IaC" },
            { title: "Stack independent" },
            { title: "REST and WebSockets" }
        ]
    },
    {
        link: "/intro/architect",
        text: "The easiest way to manage concurrency",
        points: [
            { title: "Zero setup, fully serverless IaC" },
            { title: "Stack independent" },
            { title: "REST and WebSockets" }
        ]
    }
    // { link: "", text: "" }
];

export const Layout = marketingLayoutFactory(tocData);

export default function Page() { };