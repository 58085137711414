import { Button, Col, Row } from "antd";
import { KaceyView } from "../../types";
import { useIsWideScreen } from "../../hooks/useIsWideScreen";

const shared = { fontWeight: "bold", padding: "25px 30px 50px" };

const primary = { ...shared, padding: "25px 30px 50px", fontWeight: "bold" };
const secondary = { ...shared, color: "darkorange", border: "1px solid darkorange", background: "#fffcf8" };

type ActionButtonParams = { type?: "primary" | "", href: string, text: string, icon?: React.ReactElement };

export const ActionButton: KaceyView<ActionButtonParams> = ({ href, type, text, icon }) =>
    <a key={href} href={href} style={{}}>
        <Button
            icon={icon}
            style={type == "primary" ? primary : secondary}
            size='large'
            shape='round'
            type={type || "default"}
        >{text}</Button>
    </a>;

export const ActionButtonsBlock: KaceyView<{ contact?: boolean } | undefined> = ({ contact }) => {
    const wide = useIsWideScreen();
    const w = wide ? contact ? 8 : 12 : 24;
    const props = {
        span: w,
        style: {
            marginTop: 30
        }
    }
    return <Row justify={"space-around"} style={{ textAlign: "center", fontSize: 48, marginTop: 30, marginBottom: 0 }}>
        <Col {...props}>
            <ActionButton text="Learn more" href="/docs/v1" />&nbsp;
        </Col>
        {contact ? <Col {...props}>
            <><ActionButton text="Contact Us" href="/contact" />&nbsp;</>
        </Col>  : <></>}
        <Col {...props}>
            <ActionButton text="Start a free trial" href="/auth/signup" type="primary" />
        </Col>
    </Row>
}