import Link from "next/link"
import { KaceyView } from "../../types"
import { Button, Space, Image, Card } from "antd"
import { useRouter } from "next/router"
import { useIsWideScreen } from "../../hooks/useIsWideScreen";

const capFirst = (n: string) => n.slice(0, 1).toUpperCase() + n.slice(1);


const IntroNavButton: KaceyView<{ what: string, invertColors?: boolean }> = ({ what, invertColors }) => {
    const router = useRouter();
    const p = `/intro/${what}`;
    const here = p == router.asPath;

    const _colors = ["orange", "white"];
    const _altColors = ["", ""];

    const colors = [_colors, _altColors];
    if(invertColors)
        colors.reverse();

    return <div style={{ }}>
        <Link href={p}>
            <Button 
            style={{
                maxWidth: "200px",
                fontSize: "2em",
                padding: 10,
                height: "auto",
                background: here ? colors[0][0] : colors[1][0],
                color: here ? colors[0][1] : colors[1][1],
                fontWeight: "bold"
            }}
            >
                <Image preview={false} style={{ borderRadius: 5 }} src={`/intro/images/${what}.jpeg`} />
                <div style={{ padding: "10px 0 5px" }}>{capFirst(what)}</div>
            </Button>
        </Link>
    </div>;
}

export const IntroNav: KaceyView<{ direction?: "horizontal" | "vertical", invertColors?: boolean }> = ({ direction, invertColors }) => {
    const isWide = useIsWideScreen();
    return <div className="intro-nav-buttons">
        <Space size={"large"} className="intro-nav-buttons" direction={isWide && direction ? direction : "vertical"} style={{ minWidth: "100%" }}>
            <IntroNavButton what="business" invertColors={invertColors} />
            <IntroNavButton what="architect" invertColors={invertColors} />
            <IntroNavButton what="developer" invertColors={invertColors} />
            {/* <IntroNavButton what="affiliate" /> */}
        </Space>
    </div>
}

export default IntroNav;