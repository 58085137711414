import { useResponsive } from "ahooks";
import { LayoutComponentWithTOC, TOCItems } from "./LayoutComponent";

import { Button, Card, Col, Row } from "antd";
import Link from "next/link"
import { ResponsiveDrawerLayout } from "../layouts/ResponsiveDrawerLayout";
import { ArrowLeftOutlined, ArrowRightOutlined, RightCircleFilled } from "@ant-design/icons";
import { useRandomImage } from "../../hooks/useRandomImage";
import { useTOC } from "../layouts/TOC";
import { useRouter } from "next/router";
import { LandingLayout } from "../utils/landingLayout";
import { ActionButton } from "../utils/actionButton";
import { KaceyView } from "../../types";
import { useIsWideScreen } from "../../hooks/useIsWideScreen";

export const ArticleCard: KaceyView<{ tocItem: TOCItems[0] }> = ({ tocItem }) => {
  const p = tocItem;
  const img = useRandomImage({ style: { opacity: 0.8, borderRadius: 10, margin: 10, height: 100, width: 100, minWidth: 100 } });

  return <Link href={p.link} className="no-underline" style={{ width: "100%", display: "block" }}>
    <Card
      hoverable
      style={{ minWidth: "100%", display: 'flex', flexGrow: 1, alignItems: "center" }}
      cover={img}
    >
      <Card.Meta title={p.text} description={(p.points || []).at(0)?.title} />
    </Card>
  </Link>
}

export const MoreArticles: KaceyView<{ tocData: TOCItems, wide?: boolean }> = ({ tocData, wide }) => {
  const isWideScreen = useIsWideScreen();

  return <Row gutter={10} justify={"space-between"} wrap={true} style={{ width: "100%" }}>{
    tocData.map((p, i) =>
      <Col lg={wide ? 24 : 12} xl={wide ? 24 : 12} key={i} style={{ marginBottom: 20, ...(isWideScreen ? { minWidth: "100%" } : {}) }}>
        <ArticleCard tocItem={p} key={i} />
      </Col>
    )
  }
  </Row>
}


export const MoreArticlesSmall: KaceyView<{ tocData: TOCItems, wide?: boolean }> = ({ tocData, wide }) => {
  const isWideScreen = useIsWideScreen();

  return <Row justify={"space-between"} wrap={true} style={{ width: "100%" }}>{
    tocData.map((p, i) =>
      <>
        <Link href={p.link} key={i} style={{ color: "black" }} className="title">
          <b>
            {p.text}
          </b>
          <br />
        </Link>
        <Link href={p.link} key={i} style={{ color: "black" }} className="noLinkDecoration">

          {(p.points || []).at(0)?.title}<br /><br />
          {/* <Card
          hoverable
          style={{ minWidth: "100%", display: 'flex', flexGrow: 1, alignItems: "center" }}
        >
          <Card.Meta title={p.text} description={(p.points || []).at(0)?.title} />
        </Card> */}
        </Link>
      </>
    )
  }
  </Row>
}

export const blogLayoutFactory: LayoutComponentWithTOC =
  (tocData: TOCItems) =>
    function BlogLayout({ Component, pageProps, ...rest }) {

      // const [prevIndex, menuEntryIndex, nextIndex, TOCComponent] = useTOCNextPrev(TOCData);
      const { TOC, prev, next, curr: _curr } = useTOC(tocData);

      const curr = _curr || { title: "WebSemaphore Blog", link: "/blog" };
      // const { prev, next, curr } = nav;
      const resp = useResponsive();

      const router = useRouter();
      const isRoot = router.asPath == "/blog";

      const path = router.asPath.split(/#/)[0];

      const meta = (isRoot ? tocData[0] : tocData.find(v => v.link == path));
      const isWide = useIsWideScreen();

      if (!meta)
        return <>No such thing</>;

      const more = <MoreArticles tocData={tocData} wide={true} />

      return <div {...rest} className="documentation blog">
        <ResponsiveDrawerLayout
          buttonText='More'
          drawerClassName='nav'
          // sidebar={<div>stuff</div>}
          alwaysOpen={false}
          // {<TOC />} 
          placement='left'
          sidebarWidth={300}
        // header={}
        >
          <div className="text-center"><Link href="/blog">WebSemaphore Blog</Link></div>

          <LandingLayout
            fontSize={"36pt !important"}
            hero={meta.text}
            imageMaxWidth={500}
            points={meta.points}
            secondary={
              <div className="text-right">
                <br />
                {
                  isRoot ?
                    <ActionButton href={meta.link} icon={<RightCircleFilled />} text="Continue reading" /> :
                    <></>
                }
              </div>}
          >
          </LandingLayout>

          <article>
            <Row>
              <Col span={isRoot || !isWide ? 24 : 18}>
                {/* <h1 style={{}} className="text-center">{curr?.text}</h1> */}
                <Component {...pageProps} style={{ textAlign: "justify" }} />
              </Col>
              {
                !isRoot && isWide ?
                  <Col span={6} className="more-from-the-blog">
                    <h3>More from the blog</h3>
                    <MoreArticlesSmall tocData={tocData} />
                  </Col> : <></>
              }
            </Row>

            {!isRoot ? <h2>You may be interested</h2> : <></>}
            {more}
            {/* {
              isRoot || !isWide ?
                <>
                  {more}
                </>
                :
                <></>
            } */}

          </article>

        </ResponsiveDrawerLayout>
      </div >
    };
