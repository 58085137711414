import { Menu } from "antd";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { TOCItem, TOCItems } from "../masterLayouts/LayoutComponent";
import { MenuMode } from "../RecursiveMenu";

const convertToItems: (toc: TOCItems) => MenuItemType[] =
    (toc: TOCItems = []) => {
        return toc
            .map(entry => ({
                label: <Link href={entry.link}>{entry.text}</Link>,
                key: entry.text + entry.link,
                children: entry.children && convertToItems(entry.children)
                // ...(entry.children?.length ? { children: convertToItems(entry.children) } : {})
            }))
    }

// export const MMenu = (o: any) => <>{JSON.stringify(o?.items || "")}</>


export const TOCComponent = (tocData: TOCItems, mode?: MenuMode) => <Menu mode={ mode ?? "inline" } items={convertToItems(tocData)} />;
export const TOCComponentTopLevel = (tocData: TOCItems, mode?: MenuMode) => <Menu mode={mode} items={convertToItems(tocData.map(d => ({ ...d, children: undefined })))} />;

// const recursiveFind: (data: TOCItem[], matcher: (item: TOCItem) => boolean, path?: number[]) => TOCItem | undefined = (data: TOCItem[], matcher, path) => 
//   data.find((item, index) => (matcher(item) || (recursiveFind(item.children || [], matcher, [...(path || []), index]))));



const sameUrl = (a: { link: string }, b: { link: string }) => {
    if (!a?.link || !b?.link)
        return;
    const n1 = a.link.split(/#/)[0];
    const n2 = b.link.split(/#/)[0];
    return n1 == n2;
}

/* Unfolds the TOC tree into a linear sequence for next/prev buttons */
const unfoldRecursive: (data: TOCItem[], path?: number[]) => { item: TOCItem, path: number[] }[] = (data: TOCItem[], path: number[] = []) => {
    return (data || [])
        .filter(c => !c.link || (c.link[0] != "#"))
        .reduce((r, c, i) => [
            ...r,
            { item: c, path: [...path, i] },
            ...unfoldRecursive(c.children || [], [...path, i])
        ],
            [] as { item: TOCItem, path: number[] }[])
        .filter((_d, i, arr) => !i || !sameUrl(arr[i - 1].item, arr[i].item))

}


const _nextIndex =
    (
        unfoldedToc: {
            item: TOCItem,
            path: number[] /* this is index path within the original TOC */
        }[],
        menuEntryIndex: number
    ) =>
        menuEntryIndex < unfoldedToc.length - 1 ? menuEntryIndex + 1 : 0;

export const useTOCNextPrev = (tocData: TOCItems) => {
    const { asPath } = useRouter();
    return useMemo(() => {

        // const TOCDataTopLevel = tocData.map(d => ({ text: d.text, link: d.link, children: d.children || [] }));

        const unfoldedToc = unfoldRecursive(tocData);

        const menuEntryIndex = unfoldedToc.findIndex(data => data.item.link == asPath) ?? -1;
        // const { item, path } = menuEntry || {};

        const prevIndex = menuEntryIndex > 0 ? menuEntryIndex - 1 : -1;
        const nextIndex = _nextIndex(unfoldedToc, menuEntryIndex);

        return {
            prev: unfoldedToc[prevIndex]?.item,
            curr: unfoldedToc[menuEntryIndex]?.item,
            next: unfoldedToc[nextIndex]?.item,
        }
        // return [unfoldedToc[prevIndex]?.item, unfoldedToc[menuEntryIndex]?.item, unfoldedToc[nextIndex]?.item] as TOCItem[];

        //recursiveFind(TOCData, item => item.link == asPath)
    }, [asPath, tocData])
}

export default TOCComponent;

export const useTOC = (tocData: TOCItems, mode?: MenuMode) => {
    const index = useMemo<Record<string, TOCItem>>(() => (tocData || []).reduce((r, c) => ({ ...r, [c.link]: c }), {}), [tocData])
    return {
        TOC: () => TOCComponent(tocData),
        TOCTopLevel: () => TOCComponentTopLevel(tocData, mode),
        ...useTOCNextPrev(tocData),
        index: {
            byPath: index
        }
    }
}

