export const firebaseConfig = {
    apiKey: "AIzaSyB86YzsrSikF3gmgCUH23pNJobEaklQgbE",
    authDomain: "broadmind-chainstream.firebaseapp.com",
    projectId: "broadmind-chainstream",
    storageBucket: "broadmind-chainstream.appspot.com",
    messagingSenderId: "401104065701",
    appId: "1:401104065701:web:bb27840c908a114b9de94c",
    measurementId: "G-ND42C51WBL"
  };

// const firebaseConfig = {
//     apiKey: "AIzaSyB86YzsrSikF3gmgCUH23pNJobEaklQgbE",
//     authDomain: "broadmind-chainstream.firebaseapp.com",
//     projectId: "broadmind-chainstream",
//     storageBucket: "broadmind-chainstream.appspot.com",
//     messagingSenderId: "401104065701",
//     appId: "1:401104065701:web:bb27840c908a114b9de94c",
//     measurementId: "G-ND42C51WBL"
// };