import { Signal, signal } from "@preact/signals-react";
import { SessionTokenResponse } from "websemaphore";


export const JWT_LOCALSTORAGE = "chainstream-jwt";

const localStorage = typeof window !== 'undefined' && (window as any).localStorage;

const storedJwt: string = localStorage && localStorage.getItem(JWT_LOCALSTORAGE) || "";

export const token: Signal<SessionTokenResponse> = signal({ jwt: storedJwt });

// if (storedJwt) {
//     // chainstreamClient.setSecurityData({ token: storedJwt });
//     token.value = { jwt : storedJwt };
//     // current();
// }    

