import { useResponsive } from "ahooks";
import { LayoutComponentWithTOC, TOCItems } from "./LayoutComponent";
import { Button, Col, Row } from "antd";
import Link from "next/link"
import { ResponsiveDrawerLayout } from "../layouts/ResponsiveDrawerLayout";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useRandomImage } from "../../hooks/useRandomImage";
import { useTOC } from "../layouts/TOC";

export const docsLayoutFactory: LayoutComponentWithTOC =
    (tocData: TOCItems) =>
        function DocsLayout ({ Component, pageProps, ...rest }) {
            const { TOC, prev, next, curr } = useTOC(tocData);
            const resp = useResponsive();

            return <div {...rest} className="documentation">
                <ResponsiveDrawerLayout buttonText='Docs Table of Contents' drawerClassName='nav' sidebar={<TOC />} placement='left' sidebarWidth={300} header={undefined}>
                    <article>
                        <div
                            className='semaphore-logo semaphore-logo-medium sepia semaphore-logo-responsive-float'
                        >
                            <div>
                                {useRandomImage({ style: { borderRadius: 400 } })}
                            </div>
                        </div>

                        <Component {...pageProps} style={{ textAlign: "justify" }} />

                        <Row justify={"space-between"} style={{ paddingTop: 150, width: "100%" }}>
                            <Col span={11} style={{ textAlign: "left" }}>
                                {prev && <Link href={prev.link}>
                                    <Button style={{ minHeight: "5em", width: "100%", whiteSpace: "break-spaces" }}>
                                        <Row gutter={12} align="middle" justify={"space-between"}>
                                            <Col span={6}><ArrowLeftOutlined /></Col>
                                            <Col span={18}>{prev.text}</Col>
                                        </Row>
                                    </Button></Link>}
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11} style={{ textAlign: "right" }}>
                                {next && <Link href={next.link}>
                                    <Button style={{ minHeight: "5em", width: "100%", whiteSpace: "break-spaces" }}>
                                        <Row gutter={12} align="middle" justify={"space-between"}><Col span={18}>{next.text}</Col><Col span={6}><ArrowRightOutlined /></Col></Row>
                                    </Button>
                                </Link>}
                            </Col>
                        </Row>
                    </article>
                </ResponsiveDrawerLayout>
            </div>
        };
