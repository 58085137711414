import { useInterval } from "ahooks";
import { Progress as AProgress } from "antd";
import { useState } from "react";

export const IndefiniteProgress = () => {
    const [percent, setPercent] = useState(0);
    
    useInterval(() => {
        setPercent((percent + 1) % 100)
    }, 100)

    return <AProgress percent={percent} showInfo={false} />
}