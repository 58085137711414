import { useContext } from "react";

import NXLink from "next/link";
import { Link as RRLink } from "react-router-dom";

import { reactCtx } from "./react-context";
import { KaceyView } from "../types";

export const Link: KaceyView<{ href: string }> = ({ href, children }) => {
    const ctx = useContext(reactCtx);

    return <NXLink href={href}>{children}</NXLink>; //ctx ? <RRLink to={href} /> : <NXLink href={href} />
}