import { current } from "../auth/actions";
import { token } from "../auth/token";
import { WebSemaphoreHttpClientManager } from "websemaphore";

export const chainstreamManager = WebSemaphoreHttpClientManager();

export const chainstreamClient = chainstreamManager.initialize();

if (typeof window != "undefined")
  setTimeout(() => {
    token.subscribe((val) => {
      chainstreamManager.updateToken(val?.jwt || "");
      // chainstreamClient.setSecurityData({ token: val?.jwt || "" });
      current();
    });
  })
