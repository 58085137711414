import { useResponsive } from "ahooks";
import { useIsSsrPage } from "../lib/nextutils";
import { useEffect, useState } from "react";

export const useIsWideScreen = () => {
    const isSsr = useIsSsrPage();

    const [ready, setReady] = useState(false);
    useEffect(() => {
        setReady(true);
    }, []);
    
    const resp = useResponsive() || {};
    const wideScreen = (isSsr && !ready) || resp.lg || resp.xl;

    return wideScreen;
}