import { ApikeyPagedListOwnerKeysResponse, PagedSemaphoreReadQueueResponse, SemaphoreReadResponse } from "websemaphore";
import { Store } from "./cache";
import Dexie from "dexie";

export type QueueItem = NonNullable<PagedSemaphoreReadQueueResponse['Items']>[number] & { id: string, created: string };
// export type ActivityStreamItem = { id: string, created: string, updated: string, semaphoreId: string, channelId: string };
export type ActivityStreamItem = { updated: string, semaphoreId: string, lockValue: number };

type Tables = {
    semaphore: Dexie.Table<SemaphoreReadResponse, string>;
    key: Dexie.Table<ApikeyPagedListOwnerKeysResponse, string>;
    queue: Dexie.Table<QueueItem, string>;
    activityStream: Dexie.Table<ActivityStreamItem, string>;
    // folder: Dexie.Table<MoodReadResponse, string>;
    // uploads: Dexie.Table<PostReadResponse, string>;
};
const indices = {
    semaphore: "id,owner",
    key: "id,owner",
    queue: "id,created",
    activityStream: "updated,semaphoreId"
}


export const cache = Store<Tables> ("chainstream-cache", indices);

cache.open();

