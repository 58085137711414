import { Layout, Menu, Drawer, Row, Col, Button, DrawerProps, Affix } from 'antd';
import { ReactEventHandler, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { KaceyView } from '../../types';
import { useIsWideScreen } from '../../hooks/useIsWideScreen';
const { Header, Content } = Layout;

export const ResponsiveDrawerLayout: KaceyView<{ 
    sidebar?: ReactNode,
    placement?: DrawerProps['placement'],
    drawerClassName?: string,
    header?: ReactNode,
    alwaysOpen?: boolean,
    sidebarWidth?: number,
    buttonText?: string
}> = 
({ children, sidebar, placement, header, alwaysOpen, sidebarWidth, drawerClassName, buttonText }) => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    const wideScreen = useIsWideScreen();

    const toggleDrawer = useCallback(() => {
        setDrawerVisible(!drawerVisible);
    }, [drawerVisible]);

    const [displayDrawer, setDisplayDrawer] = useState(false);
    useEffect(() => setDisplayDrawer(drawerVisible || wideScreen), [drawerVisible, wideScreen])

    const rootRef = useRef<any>();

    const closeOnOutsideClick: ReactEventHandler = (ev) => {
        const tgt = ev.target as HTMLDivElement;

        if (!drawerVisible || (rootRef.current && rootRef.current!.contains(tgt)))
            return;

        setDrawerVisible(false);
    }

    const affixedSidebar = useMemo(() => <div className={drawerClassName || ""}>{sidebar}</div>, [sidebar, drawerClassName]);
    // const affixedSidebar = useMemo(() => <Affix className={drawerClassName || ""}>{sidebar}</Affix>, [sidebar, drawerClassName]);
    const asDrawer = !alwaysOpen && !wideScreen; 

    const drawer = useMemo(() => <Drawer
        title={header}
        placement={placement || "left"}
        closable={!!asDrawer}
        onClose={() => toggleDrawer()}
        maskClosable={true}
        open={displayDrawer}
        getContainer={false}
        mask={false}
        maskStyle={{ background: "rgba(255,255,255,0.3)", border: "none" }}
        rootStyle={{ position: !asDrawer ? 'absolute' : 'static', background: "rgba(255,255,255,0.3)", border: "none" }}
    >
        {affixedSidebar}
    </Drawer>
    , [placement, asDrawer, displayDrawer, affixedSidebar, toggleDrawer, header]);

    const sidebarOpener = useMemo(() => !wideScreen && <Button className="drawer-toggle" onClick={(e) => toggleDrawer()}>
        <MenuOutlined /> {buttonText || ""}
    </Button>, [toggleDrawer, wideScreen, buttonText]);


    const content = useMemo(() => {
        const sidebarCollapsedWidth = 70;// header ? 0 : 80;
        const _sidebarWidth = sidebar ? wideScreen ? sidebarWidth : sidebarCollapsedWidth : 0;
        const contentWidth = wideScreen ? `calc(100% - ${_sidebarWidth}px)` : `calc(100%)`;

        const cont = [
            sidebar  ?<Col key="1" style={{ width: sidebarWidth, padding: 0 }}>
                {!header && asDrawer && sidebarOpener}
                <div>{!asDrawer ? affixedSidebar : ""}</div>
            </Col> : <></>,
            <Col key="2" style={{ width: contentWidth, maxWidth: contentWidth }}>
                <Row style={{ minHeight: "100vh" }}>
                    <div style={{ marginRight: "auto", marginLeft: "auto", width: "100%" }}>
                        {children}
                        {/* <Component {...pageProps} /> */}
                    </div>
                </Row>
            </Col>
        ];

        // if (placement === "right")
        //     cont.reverse();

            return <Row className={placement == "right" ? "responsive-reverse-block" : ""}>{cont}</Row>;
        }, [header, wideScreen, asDrawer, sidebarOpener, affixedSidebar, children, placement, sidebarWidth, sidebar]);


    return (<>
        <div onClick={closeOnOutsideClick}>
            {header && <Header className="header">
                {asDrawer && sidebarOpener}
                {header}
            </Header>}
            <Content className="site-layout">
                {content}
                <div ref={rootRef}>{asDrawer ? drawer : ""}</div>
            </Content>
        </div >
    </>

    );
};


// export const ResponsiveDrawerLayout = typeof window == "undefined" ? () => <></> : _ResponsiveDrawerLayout;