
import { token } from "../auth/token";
import { chainstreamWebsocketsClientManager } from "./client";
import { processIncoming } from "./processIncoming";
import EventEmitter from "events";

export const websocketsEvents = new EventEmitter();

export const chainstreamWebsocketsClient = chainstreamWebsocketsClientManager((wsServer, token) => `${wsServer}?token=${encodeURIComponent(token)}`);;

export const setChainstreamWebsocketToken = (token: string) => {
  console.log(token);
  if (!token) return;

  chainstreamWebsocketsClient.toggle(token);

  if (!token || !chainstreamWebsocketsClient.socket) return;

  chainstreamWebsocketsClient.socket?.addEventListener("message", (ev) => {
    if(ev.data == "pong") return;
    
    websocketsEvents.emit("message", ev.data)
  });

  websocketsEvents.on("message", (ev: any) => {
    if (ev.data === "pong") return;

    processIncoming(ev.data);
  });
};

token.subscribe((token) => setChainstreamWebsocketToken(token.jwt || ""));