// components/NoSsr.jsx

import dynamic from 'next/dynamic'
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react'
import { KaceyView } from '../types';

const _NoSsr = (props: any) => (
  <React.Fragment>{props.children}</React.Fragment>
)

export default dynamic(() => Promise.resolve(_NoSsr), {
  ssr: false
})

export const NoSsr = _NoSsr; 

const ssrPaths = [
    "/about","/privacy","/tos","/docs","/demos/simulation"
]

export const useIsSsrPage = () => {
    const { pathname } = useRouter();

    return pathname == "/" || (ssrPaths.find(p => pathname.startsWith(p)));
}

export const useOnMounted = (f?: () => void) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => { 
    setMounted(true);
    f && f();
    //@ts-ignore
  }, [f]);

  return mounted;
}

export const NoSsrOnMounted: KaceyView<{ noSsr: boolean }> = (props) => {
  const isMounted = useOnMounted();

  if(!isMounted && props.noSsr)
    return <></>;

  return <React.Fragment>{props.children}</React.Fragment>
}
