// import { MoodReadResponse, PostReadResponse, UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import Dexie from "dexie";

// type IGraphObject = { id: string };

type IGraphEdge = {
    id?: string;
    fromLabel?: string;
    toLabel?: string;
    from?: string;
    to?: string;
    value?: any;
    label: string;
};

type IGraphEdgeEntry = IGraphEdge & {
    cached: string;
    __outE: string;
    __inE: string;
}

const EDGES_TABLE_NAME = "__EDGES";

class _Store extends Dexie {
    [EDGES_TABLE_NAME]!: Dexie.Table<IGraphEdgeEntry, number>;

    constructor(name: string = "chainstream-cache", stores: Record<string, string>) {
        super(name);

        //
        // Define tables and indexes
        // (Here's where the implicit table props are dynamically created)
        //
        this.version(1).stores({
            ...stores,
            [EDGES_TABLE_NAME]: "++id,__outE,__inE",
        });
    }

    async storeEdges(edges: IGraphEdge[]) {
        return Promise.all(edges.map(e => this.storeEdge(e)));
    }

    storeEdge({ id, fromLabel, toLabel, label, from, to }: IGraphEdge) {
        if (!from || !to) {
            console.warn("cache2: attempted to cache a bad edge, skipping");
            return;
        }

        const __outE = [fromLabel, from, label, toLabel, to].join("+");
        const __inE = [toLabel, to, label, fromLabel, from].join("+");

        return this[EDGES_TABLE_NAME].put({
            __outE,
            __inE,
            label,
            fromLabel,
            from: from,
            toLabel,
            to: to,
            cached: new Date().toISOString(),
            id: id || __outE
        });
    };
}

export const Store = <T>(name: string = "iosdk-cache", stores: Record<string, string>) => {
    return (new _Store(name, stores)) as (T & (_Store));
}