import { Menu } from "antd";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import Link from "next/link"
import { useRouter } from "next/router";
import { useMemo } from "react";

export const TOCData = [
  {
    "text": "Docs home",
    "link": "/docs/v1"
  },
  {
    "text": "Introduction",
    "link": "/docs/v1/introduction",
    "children": [
      {
        "text": "Overview",
        "link": "/docs/v1/introduction#overview"
      },
      {
        "text": "Key Features",
        "link": "/docs/v1/introduction#key-features"
      },
      {
        "text": "Quick Start",
        "link": "/docs/v1/getting-started"
      }
    ]
  },
  {
    "text": "Concepts",
    "link": "/docs/v1/concepts",
    "children": [
      {
        "text": "Terms",
        "link": "/docs/v1/concepts/terms"
      },
      {
        "text": "Operating modes",
        "link": "/docs/v1/concepts/operating-modes"
      },
      {
        "text": "Systems Integration Scenarios",
        "link": "/docs/v1/concepts/scenarios-integration"
      },
      {
        "text": "Protocols",
        "link": "/docs/v1/concepts/protocols"
      }
    ]
  },
  {
    "text": "Setup",
    "link": "/docs/v1/setup",
    "children": [
      {
        "text": "Prerequisites",
        "link": "/docs/v1/setup/prerequisites"
      },
      {
        "text": "Sign up to WebSemaphore",
        "link": "/docs/v1/setup/signup"
      },
      {
        "text": "Create a Web Semaphore",
        "link": "/docs/v1/setup/semaphore"
      },
      {
        "text": "Create an API key",
        "link": "/docs/v1/setup/key"
      }
      // ,
      // {
      //   "text": "Use WebSemaphore in your code",
      //   "link": "/docs/v1/setup/usage"
      // }
    ]
  },
  {
    "text": "Usage",
    "link": "/docs/v1/usage",
    "children": [
      {
        "text": "Overview",
        "link": "/docs/v1/usage"
      },
      {
        "text": "Install the client library",
        "link": "/docs/v1/usage/install-client"
      },
      {
        "text": "Websockets Usage",
        "link": "/docs/v1/usage/websockets"
      },
      {
        "text": "HTTP/REST Usage",
        "link": "/docs/v1/usage/rest"
      }
      //,
      // {
      //   "text": "Releasing a Semaphore",
      //   "link": "#releasing-a-semaphore"
      // },
      // {
      //   "text": "Checking Semaphore Status",
      //   "link": "#checking-semaphore-status"
      // },
      // {
      //   "text": "Updating Semaphore Configuration",
      //   "link": "#updating-semaphore-configuration"
      // },
      // {
      //   "text": "Advanced Usage Examples",
      //   "link": "#advanced-usage-examples"
      // }
    ]
  },
  {
    "text": "API",
    "link": "/docs/v1/api",
    "children": [
      {
        "text": "Semaphore Configuration",
        "link": "/docs/v1/api/semaphore"
      },
      {
        "text": "Mapping handler",
        "link": "/docs/v1/api/mapping"
      },
      {
        "text": "REST",
        "link": "/docs/v1/api/rest"
      },
      {
        "text": "Websockets",
        "link": "/docs/v1/api/websockets"
      }
    ]
  },
  {
    "text": "Examples",
    "link": "/docs/v1/examples",
    "children": [
      {
        text: "Reference implementations",
        link: "/docs/v1/examples"
      }
      // {
      //   "text": "Real-time Chat Application with Websemaphore",
      //   "link": "#real-time-chat-application-with-websemaphore"
      // },
      // {
      //   "text": "Implementing Rate Limiting with Websemaphore",
      //   "link": "#implementing-rate-limiting-with-websemaphore"
      // },
      // {
      //   "text": "Managing Concurrent Operations in a Distributed System",
      //   "link": "#managing-concurrent-operations-in-a-distributed-system"
      // }
    ]
  },
  {
    "text": "Interactive Simulation",
    "link": "/demos/simulation"
  },
  {
    "text": "Technology landscape",
    "link": "/docs/v1/comparisons"
    // "children": [
    //   {
    //     "text": "Apigee",
    //     "link": "/docs/v1/comparisons/apigee"
    //   },
    //   {
    //     "text": "Mulesoft",
    //     "link": "/docs/v1/comparisons/mulesoft"
    //   },
    //   {
    //     "text": "AWS SQS",
    //     "link": "/docs/v1/comparisons/sqs"
    //   },
    //   {
    //     "text": "AWS Async Lambda",
    //     "link": "/docs/v1/comparisons/asyncLambda"
    //   },
    //   {
    //     "text": "Webhooks",
    //     "link": "/docs/v1/comparisons/webhooks"
    //   }

    // ]
  },
  {
    "text": "Terms",
    "link": "/docs/v1/tos",
  },
  {
    "text": "Privacy",
    "link": "/docs/v1/privacy",
  }
];

export const TOCDataTopLevel = TOCData.map(d => ({ text: d.text, link: d.link, children: d.children || [] }));

type TOCItem = { text: string, link: string, children?: TOCItem[] | undefined };

const convertToItems: (toc: TOCItem[]) => MenuItemType[] =
  (toc: TOCItem[]) => {
    return toc
      .map(entry => ({
        label: <Link href={entry.link}>{entry.text}</Link>,
        key: entry.text + entry.link,
        children: entry.children && convertToItems(entry.children)
        // ...(entry.children?.length ? { children: convertToItems(entry.children) } : {})
      }))

  }

// export const MMenu = (o: any) => <>{JSON.stringify(o?.items || "")}</>


export const TOCComponent = () => <Menu mode="inline" items={convertToItems(TOCData)} />;
export const TOCComponentTopLevel = () => <Menu items={convertToItems(TOCDataTopLevel)} />;

// const recursiveFind: (data: TOCItem[], matcher: (item: TOCItem) => boolean, path?: number[]) => TOCItem | undefined = (data: TOCItem[], matcher, path) => 
//   data.find((item, index) => (matcher(item) || (recursiveFind(item.children || [], matcher, [...(path || []), index]))));



const sameUrl = (a: { link: string }, b: { link: string }) => {
  if (!a?.link || !b?.link)
    return;
  const n1 = a.link.split(/#/)[0];
  const n2 = b.link.split(/#/)[0];
  return n1 == n2;
}

/* Unfolds the TOC tree into a linear sequence for next/prev buttons */
const unfoldRecursive: (data: TOCItem[], path?: number[]) => { item: TOCItem, path: number[] }[] = (data: TOCItem[], path: number[] = []) => {
  return data
    .filter(c => !c.link || (c.link[0] != "#"))
    .reduce((r, c, i) => [
      ...r,
      { item: c, path: [...path, i] },
      ...unfoldRecursive(c.children || [], [...path, i])
    ],
      [] as { item: TOCItem, path: number[] }[])
    .filter((_d, i, arr) => !i || !sameUrl(arr[i - 1].item, arr[i].item))

}
export const unfoldedToc = unfoldRecursive(TOCData);


const _nextIndex = (menuEntryIndex: number) => menuEntryIndex < unfoldedToc.length - 1 ? menuEntryIndex + 1 : 0;

export const useTOCNextPrev = () => {
  const { asPath } = useRouter();
  return useMemo(() => {
    const menuEntryIndex = unfoldedToc.findIndex(data => data.item.link == asPath) ?? -1;
    // const { item, path } = menuEntry || {};

    const prevIndex = menuEntryIndex > 0 ? menuEntryIndex - 1 : -1;
    const nextIndex = _nextIndex(menuEntryIndex);

    return [unfoldedToc[prevIndex]?.item, unfoldedToc[menuEntryIndex]?.item, unfoldedToc[nextIndex]?.item] as TOCItem[];

    //recursiveFind(TOCData, item => item.link == asPath)
  }, [asPath])
}

export default TOCComponent;