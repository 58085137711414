export const websocketsServers = {
  "dev": "wss://wsapi-eu-dev.websemaphore.com/v1",
  "prod": "wss://wsapi.websemaphore.com/v1",
};

export const websocketsServer = websocketsServers["prod"];

export type WSState = {
    socket: WebSocket | null;
    toggle: (token: string) => void;
    url: string;
    send: (msg: any) => void;
    token: string
  };


  
export const chainstreamWebsocketsClientManager = (upd: (wsServer: string, token: string) => string) => {
    const state = {
      socket: null,
      token: ""
    } as WSState;
  
    let pingInterval: ReturnType<typeof setInterval>;
    let pingCounter = 0;
  
    const outboundQueue: any[] = [];

    const processPong = (ev: any) => {
      if (ev.data === "pong") {
        pingCounter--;
        console.log("pong, pingCounter == ", pingCounter, new Date().toISOString());
      }
    };
  
    const startPing = () =>
      (pingInterval = setInterval(() => {
        state?.socket?.send("ping");
        pingCounter++;
        console.log("ping, pingCounter == ", pingCounter);
      }, 10000));
    const stopPing = () => {
      clearInterval(pingInterval);
    };

    let noReconnect = false;

    const onClose = () => {
      stopPing();
      if(!noReconnect && state.token)
        toggle(state.token)        
      noReconnect = false;
    }

    const toggle = async (token: string) => {
      state.token = token;
      const url = upd(websocketsServer, token);
  
      const togglingOff = !token; // && url;

      if (state.url === url && token == state.token && state.socket?.readyState == state.socket?.OPEN) 
        return;
  
      state.url = url;
  
      if (state.socket) {
        state.socket.removeEventListener("message", processPong);
        state.socket.removeEventListener("open", startPing);
        state.socket.removeEventListener("close", stopPing);
  
        noReconnect = true;

        state.socket.close();
        
        
        state.socket = null;
      }

  
      if (!togglingOff) {
        state.socket = new WebSocket(state.url);
        state.socket.addEventListener("open", startPing);
        state.socket.addEventListener("close", onClose);

        state.socket.addEventListener("error", (error) => {
          console.log(error);
        });

        state.socket.addEventListener("message", processPong);
      }
    };
    state.toggle = toggle;
    state.send = (msg: any) => {
      if (state.socket?.readyState == state.socket?.CONNECTING) {
        outboundQueue.push(msg);
        return
      }
  
      if (typeof msg != "string")
        msg = JSON.stringify(msg);
  
      state.socket?.send(msg)
    }
  
    return state;
  };
  