import { ProgressCallback } from "./types";

export class ProgressEntry {
    inProgress: boolean = false;
    promise?: Promise<Partial<ProgressEntry>>;
    page: number | string = 0;
    done: boolean = false;
    paused: boolean = false;
    updated: string = "";
    key: string;
    error?: Error;
    autostart?: boolean;

    throttle?: {
      _lastCalled?: string,
      // _lastExecuted: 0,
      wait?: number,
      maxWait?: number,
      leading?: number,
      trailing?: number
    };

    executions: number = 0;

    onContinue?: ProgressCallback;
    onReset?: ProgressCallback;

    init: Partial<ProgressEntry>;
  
    constructor(key: string, init?: Partial<ProgressEntry>) {
      this.key = key;
      init && (Object.assign(this, init));
      this.updated = new Date().toISOString();
      this.init = init ? { ...init } : {};
    }
    clone(pe: Partial<ProgressEntry>): ProgressEntry {
    //   console.log(`Progress for ${pe.key} updated to ${JSON.stringify(pe)}`);
      return new ProgressEntry(this.key, { ...this, ...pe, onContinue: this.onContinue, onReset: this.onReset });
    }
    exec(params?: unknown) {
      this.onContinue && this.onContinue(this, params);
    }
    continue(params?: unknown) {
      this.onContinue && this.onContinue(this, params);
    }
    reset() {
      // this.clone(this.init);
      // Object.assign
      // console.log(this.onReset)
      this.onReset && this.onReset(this);
    }
  }
  