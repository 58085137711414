import { docsLayoutFactory } from "../../../components/masterLayouts/docs";

export const tocData = [
    {
      "text": "Docs home",
      "link": "/docs/v1"
    },
    {
      "text": "Introduction",
      "link": "/docs/v1/introduction",
      "children": [
        {
          "text": "Overview",
          "link": "/docs/v1/introduction#overview"
        },
        {
          "text": "Key Features",
          "link": "/docs/v1/introduction#key-features"
        },
        {
          "text": "Quick Start",
          "link": "/docs/v1/getting-started"
        }
      ]
    },
    {
      "text": "Concepts",
      "link": "/docs/v1/concepts",
      "children": [
        {
          "text": "Terms",
          "link": "/docs/v1/concepts/terms"
        },
        {
          "text": "Operating modes",
          "link": "/docs/v1/concepts/operating-modes"
        },
        {
          "text": "Systems Integration Scenarios",
          "link": "/docs/v1/concepts/scenarios-integration"
        },
        {
          "text": "Protocols",
          "link": "/docs/v1/concepts/protocols"
        }
      ]
    },
    {
      "text": "Setup",
      "link": "/docs/v1/setup",
      "children": [
        {
          "text": "Prerequisites",
          "link": "/docs/v1/setup/prerequisites"
        },
        {
          "text": "Sign up to WebSemaphore",
          "link": "/docs/v1/setup/signup"
        },
        {
          "text": "Create a Web Semaphore",
          "link": "/docs/v1/setup/semaphore"
        },
        {
          "text": "Create an API key",
          "link": "/docs/v1/setup/key"
        }
        // ,
        // {
        //   "text": "Use WebSemaphore in your code",
        //   "link": "/docs/v1/setup/usage"
        // }
      ]
    },
    {
      "text": "Usage",
      "link": "/docs/v1/usage",
      "children": [
        {
          "text": "Overview",
          "link": "/docs/v1/usage"
        },
        {
          "text": "Install the client library",
          "link": "/docs/v1/usage/install-client"
        },
        {
          "text": "HTTP/REST Usage",
          "link": "/docs/v1/usage/rest"
        },
        {
          "text": "Websockets Usage",
          "link": "/docs/v1/usage/websockets"
        }
        //,
        // {
        //   "text": "Releasing a Semaphore",
        //   "link": "#releasing-a-semaphore"
        // },
        // {
        //   "text": "Checking Semaphore Status",
        //   "link": "#checking-semaphore-status"
        // },
        // {
        //   "text": "Updating Semaphore Configuration",
        //   "link": "#updating-semaphore-configuration"
        // },
        // {
        //   "text": "Advanced Usage Examples",
        //   "link": "#advanced-usage-examples"
        // }
      ]
    },
    {
      "text": "API",
      "link": "/docs/v1/api",
      "children": [
        {
          "text": "Semaphore Configuration",
          "link": "/docs/v1/api/semaphore"
        },
        {
          "text": "Mapping handler",
          "link": "/docs/v1/api/mapping"
        },
        {
          "text": "REST",
          "link": "/docs/v1/api/rest"
        },
        {
          "text": "Websockets",
          "link": "/docs/v1/api/websockets"
        }
      ]
    },
    {
      "text": "Examples",
      "link": "/docs/v1/examples",
      "children": [
        {
          text: "Reference implementations",
          link: "/docs/v1/examples"
        }
        // {
        //   "text": "Real-time Chat Application with Websemaphore",
        //   "link": "#real-time-chat-application-with-websemaphore"
        // },
        // {
        //   "text": "Implementing Rate Limiting with Websemaphore",
        //   "link": "#implementing-rate-limiting-with-websemaphore"
        // },
        // {
        //   "text": "Managing Concurrent Operations in a Distributed System",
        //   "link": "#managing-concurrent-operations-in-a-distributed-system"
        // }
      ]
    },
    {
      "text": "Interactive Simulation",
      "link": "/demos/simulation"
    },
    {
      "text": "Technology landscape",
      "link": "/docs/v1/comparisons"
      // "children": [
      //   {
      //     "text": "Apigee",
      //     "link": "/docs/v1/comparisons/apigee"
      //   },
      //   {
      //     "text": "Mulesoft",
      //     "link": "/docs/v1/comparisons/mulesoft"
      //   },
      //   {
      //     "text": "AWS SQS",
      //     "link": "/docs/v1/comparisons/sqs"
      //   },
      //   {
      //     "text": "AWS Async Lambda",
      //     "link": "/docs/v1/comparisons/asyncLambda"
      //   },
      //   {
      //     "text": "Webhooks",
      //     "link": "/docs/v1/comparisons/webhooks"
      //   }
  
      // ]
    },
    {
      "text": "Terms",
      "link": "/docs/v1/tos",
    },
    {
      "text": "Privacy",
      "link": "/docs/v1/privacy",
    }
  ];
  
export const Layout = docsLayoutFactory(tocData);

export default function () {};