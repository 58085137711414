// this is not a master layout, rather 
// it does the 60/30 two-col layout automatically

import { Col, Row } from "antd";
import { KaceyView } from "../../types";
import { useRandomImage } from "../../hooks/useRandomImage";
import Link from "next/link";
import { useResponsive } from "ahooks";


const subBlock = ({ title, text, path, icon }: { title: string, text?: string, path?: string, icon?: JSX.Element }) => {
    if (path)
        return <Link href={path} className='hoverable-landing' style={{ padding: 24, display: "block" }}>
            <Row gutter={18} align={"middle"}>
                <Col key={21} span={4}>
                    {icon}
                </Col>
                <Col key={22} span={20}>
                    <h3 style={{ margin: 0 }}>{title}</h3>
                    {(text || "").trim() ? <div className={path ? '' : "landing-block-text"}>{text}</div> : <></>}
                </Col>
            </Row>
        </Link>

    return <><h3 style={{ margin: 0, marginBottom: "1em" }}>{icon} {title}</h3>{text ? <div className='landing-block-text'>{text}</div> : <></>}</>
}


export const LandingLayoutHero: KaceyView<{ secondary?: React.ReactNode }> = ({ secondary, children }) => {
    const ri =  useRandomImage({ className: "xaffix-hidden sepia", style: { maxWidth: 600, borderRadius: 400 } });

    return <Row gutter={48} align={"top"} className="hero">
        <Col key={"11"} lg={9} style={{ alignSelf: "center" }} className="hero-text-container">
            {children}
            {/* <h1 className='hero-main'>Business continuity for APIs at cloud scale</h1>
        <div className='hero-explainer'>
            WebSemaphore manages concurrent access to resources as a service. Step into the future with scalable, optimized API operations and lossless near-realtime communication.
        </div>
        <ActionButtonsBlock />
        <br />
        <br /> */}
        </Col>
        <Col key={"12"} lg={15} style={{ justifyContent: "end", display: "flex", padding: "0 24px 60px 24px" }}>
            {/* <Affix offsetTop={104}> */}
            <div>
                <div className='semaphore-logo'>
                    <div className='semaphore-logo-comp'>
                    </div>
                    {secondary || ri}
                </div>
            </div>
        </Col>
    </Row>
}

export const LandingLayout: KaceyView<{
    rtl?: boolean,
    hero: string,
    points?: { title: string, text?: string, path?: string, icon?: JSX.Element }[],
    secondary?: React.ReactNode, animatedElementRef?: any,
    imageMaxWidth?: number,
    fontSize?: number | string
}> = ({ hero, secondary, children, animatedElementRef, rtl, points: content, imageMaxWidth, fontSize }) => {
    const resp = useResponsive();
    const imgr = useRandomImage;

    return <Row>
        <Col key={11} xl={8} lg={8} sm={24} xs={24} style={{ alignSelf: "center", padding: "24px 0" }}>
            <h1 className="hero-main" style={{ fontWeight: "bold", ...(fontSize ? { fontSize } : {}) }}>{hero}</h1>
            {(content || []).map(bc => subBlock(bc))}
            {secondary}
        </Col>
        <Col key={12} xl={16} lg={16} sm={24} xs={24} style={{ alignSelf: "center", padding: resp?.xxl ? 150 : resp?.xl ? 130 : 30 }}>
            <div style={{ textAlign: "right", maxWidth: imageMaxWidth || 800, marginLeft: "auto", marginRight: "auto" }} ref={animatedElementRef} className='semaphore-logo semaphore-logo-medium sepia'>
                {imgr({ style: { borderRadius: 800 } })}
            </div>
        </Col>
        <Col span={24}>{children}</Col>
    </Row>
}