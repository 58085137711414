import { useResponsive } from "ahooks";
import { LayoutComponentWithTOC, TOCItems } from "./LayoutComponent";

import { Button, Col, Row } from "antd";
import Link from "next/link";
import { ResponsiveDrawerLayout } from "../layouts/ResponsiveDrawerLayout";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useTOC } from "../layouts/TOC";
import { useRouter } from "next/router";
import { LandingLayout } from "../utils/landingLayout";
import { IntroNav } from "../../pages/intro/nav";
import { useIsWideScreen } from "../../hooks/useIsWideScreen";
import { ActionButtonsBlock } from "../utils/actionButton";

export const marketingLayoutFactory: LayoutComponentWithTOC =
  (tocData: TOCItems) =>
    function BlogLayout({ Component, pageProps, ...rest }) {
      const { TOC, prev, next, curr: _curr, index } = useTOC(tocData);

      const curr = _curr || { title: "WebSemaphore blog", link: "/blog" };
      const resp = useResponsive();

      const router = useRouter();
      const isRoot = router.asPath == "/blog";

      const meta = index.byPath[router.asPath];

      const isWide = useIsWideScreen();

      return <div {...rest} className="documentation">
        <ResponsiveDrawerLayout
          buttonText='More'
          drawerClassName='nav'
          placement='left'
          sidebarWidth={300}
        >
          <article>
            <LandingLayout hero={meta.text} points={meta.points}>
              <Row gutter={48}>
                {
                  isWide ?
                    <Col xs={24} md={8}>
                      <IntroNav />
                    </Col>
                    :
                    <></>
                }
                <Col xs={24} md={16} className="intro-article">
                  <Component {...pageProps} style={{ textAlign: "justify" }} />
                </Col>
                <Col span={isWide ? 16 : 24} push={isWide ? 8 : 0}>
                  <h3>Check the intro for other profiles:</h3>
                  <IntroNav direction="horizontal" invertColors={true} />
                </Col>
                <Col span={isWide ? 16 : 24} style={{ padding: "100px 0" }} push={isWide ? 8 : 0}>
                  <ActionButtonsBlock contact={true} />
                </Col>
              </Row>


              {/* {!isWide ? <> */}
              {/* </> : <></>} */}

            </LandingLayout>


            {/* <Row justify={"space-between"} style={{ paddingTop: 150, width: "100%" }}>
              <Col span={11} style={{ textAlign: "left" }}>
                {prev && <Link href={prev.link}>
                  <Button style={{ minHeight: "5em", width: "100%", whiteSpace: "break-spaces" }}>
                    <Row gutter={12} align="middle" justify={"space-between"}>
                      <Col span={6}><ArrowLeftOutlined /></Col>
                      <Col span={18}>{prev.text}</Col>
                    </Row>
                  </Button></Link>}
              </Col>
              <Col span={2}></Col>
              <Col span={11} style={{ textAlign: "right" }}>
                {next && <Link href={next.link}>
                  <Button style={{ minHeight: "5em", width: "100%", whiteSpace: "break-spaces" }}>
                    <Row gutter={12} align="middle" justify={"space-between"}><Col span={18}>{next.text}</Col><Col span={6}><ArrowRightOutlined /></Col></Row>
                  </Button>
                </Link>}
              </Col>
            </Row> */}
          </article>
        </ResponsiveDrawerLayout>
      </div >
    };


