import { Image } from "antd";
import { useRouter } from "next/router";
import { CSSProperties, useEffect, useMemo, useState } from "react";

let unused = [] as number[];

const makeUnused = () => {
  unused.splice(0, 0);
  " ".repeat(20).split("").map((_, i) => unused.push(i + 1));
}

const randomImageId = () => {
  const ri = Math.floor(Math.random() * unused.length);
  const rv = unused[ri];
  unused = unused.filter((_, i) => i != ri);
  console.log({ unused })
  return rv.toString();
}

const shuffle = [] as string[];

const makeShuffle = () => {
  shuffle.splice(0, 0);
  const unusedLen = unused.length;
  for (let i = 0; i < unusedLen; i++)
    shuffle.push(randomImageId());
}

export const useRandomImage = (params?: { style?: CSSProperties, wrapperStyle?: CSSProperties, className?: string }) => {
  const [ssr, setSsr] = useState(true);
  useEffect(() => {
    setSsr(false);
  }, [])
  
  if (!shuffle.length) {
    makeUnused();
    if(!ssr) makeShuffle();
  }

  const { route } = useRouter();
  const img = useMemo(() => `/semaphore${shuffle.pop()}.jpeg`, [route, ssr]);

  return <Image src={img} alt=""
    wrapperStyle={params?.wrapperStyle || {}}
    style={params?.style || {}}
    preview={false}
    className={params?.className}
  />

}